* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  outline: none;
  border: none;
  text-decoration: none;
  list-style: none;
  font-size: 15px;
}

body {
  font: 14px/22px 'Hind', sans-serif;
}

@media screen and (min-width: 321px) {
  body {
    font-size: 15px;
  }
}

@media screen and (min-width: 478px) {
  body {
    font-size: 15px;
  }
}


::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: #b2b9c4;
}

::-webkit-scrollbar-thumb {
  background-color: #b2b9c4;
}

.form-container {
  display: grid;
  gap: 15px;
}

.strong {
  font-weight: 600;
}

.cursor-pointer {
  cursor: pointer;
}

.ss-full-width,
.project-single .article.ss-full-width {
  padding: 15px !important;
}

@media screen and (min-width: 500px) {

  .ss-full-width,
  .project-single .article.ss-full-width {
    padding: 30px !important;
  }
}


@media screen and (max-width: 1230px) {

  #page,
  .container {
    width: 100% !important;
  }
}